<template>
  <label v-bind:for="component_id" v-if="component_meta.component.properties.show_label == 'Y'">
    {{ component_meta.label }}：
  </label>
  <span class="tip" v-if="component_meta.component.properties.show_label == 'Y'">{{ component_meta.tip }}</span>

  <div class="float-right">
    <button type="button" class="btn btn-default bg-green" title="新增分類" @click="add_class">
      <i class="fas fa-th-list"></i>
    </button>
    <button type="button" class="btn btn-default bg-blue" title="新增項目" @click="add_item">
      <i class="far fa-file"></i>
    </button>
  </div>
  <div class="clearfix mb-3"></div>
  <div v-bind:ref="component_id" class="menu-frame" v-bind:id="component_id">
    <Item v-for="(item, index) in component_value" v-bind:key="item.id" v-bind:item="item"
      @delete_event="delete_event(index, $event)" @input="input_event(index, $event)">
    </Item>
  </div>
</template>

<script>
import $ from 'jquery'
import Item from "./Item.vue"
export default {
  props: ['field', 'value', 'message'],
  emits: ['input', ''],
  components: {
    Item
  },
  data() {
    return {
      component_id: 'date-' + Math.random().toString().substring(2),
      component_meta: this.field,
      component_value: this.value
    }
  },
  mounted() {
    this.refresh_sortable_event();
  },
  methods: {
    add_class: function () {
      this.component_value.push({
        "id": 'item-' + Math.random().toString().substring(2),
        "title": "",
        "icon": "",
        "subitems": []
      });
      this.refresh_sortable_event();
    },
    add_item: function () {
      this.component_value.push({
        "id": 'item-' + Math.random().toString().substring(2),
        "title": "",
        "icon": "",
        "path": ""
      });
      this.refresh_sortable_event();
    },
    delete_event: function (index, $event) {
      this.component_value.splice(index, 1);
      $event.temp = this.component_value;
      this.$emit('input', $event);
      this.$forceUpdate();
    },
    input_event: function (index, $event) {
      this.component_value[index] = ($event.temp == undefined) ? $event.target.value : $event.temp;
      $event.temp = this.component_value;
      this.$emit('input', $event);
      this.$forceUpdate();
    },
    refresh_sortable_event: function () {
      let $this = this;
      $(this.$refs[this.component_id]).sortable({
        // cancel: "div.row",
        handle: ".item-icon, .list-icon",
        items: "> .item-frame, > .list-frame",
        update: function (event, ui) {
          ui.item = undefined;
          
          let sort_array = [];
          $('> .item-frame, > .list-frame', $(event.target)).each(function () {
            sort_array.push($(this).attr('id'));
          });
          
          let sortedArr = $.map(sort_array, function (id) {
            return $.grep($this.component_value, function (obj) {
              return obj.id === id;
            })[0];
          });
          $this.component_value = sortedArr;
          let $event = { 'temp': $this.component_value };
          $this.$emit('input', $event);
        }
      });
    }
  }
}
</script>

<style>
span.tip {
  color: green;
}

div.menu-frame {
  border: 1px solid gray;
  padding: 20px;
  border-radius: 5px;
}
</style>