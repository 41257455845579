<template>
  <div v-bind:ref="component_id" v-bind:id="menu_item.id" class="list-frame" v-if="menu_item.path == undefined">
    <div class="row">
      <div class="col-md-1">
        <div class="list-icon"><i class="fas fa-th-list"></i></div>
      </div>
      <div class="col-md-2"><input type="text" class="form-control" v-bind:value="menu_item.icon"
          @change="icon_change_event($event)" /></div>
      <div class="col-md-7"><input type="text" class="form-control" v-bind:value="menu_item.title"
          @change="title_change_event($event)" /></div>
      <div class="col-md-2">
        <button type="button" class="btn bg-green" title="新增分類" @click="add_class">
          <i class="fas fa-th-list"></i>
        </button>
        <button type="button" class="btn bg-blue" title="新增項目" @click="add_item">
          <i class="far fa-file"></i>
        </button>
        <button type="button" class="btn bg-red" title="刪除項目" @click="delete_item">
          <i class="fas fa-trash"></i>
        </button>
      </div>
    </div>
    <template v-for="(item, index) in menu_item.subitems" v-bind:key="item.id">
      <item v-bind:item="item" @delete_event="delete_event(index, $event)" @input="input_event(index, $event)"></item>
    </template>
  </div>

  <div class="item-frame" v-bind:id="menu_item.id" v-else>
    <div class="row">
      <div class="col-md-1">
        <div class="item-icon"><i class="far fa-file"></i></div>
      </div>
      <div class="col-md-2"><input type="text" class="form-control" v-bind:value="menu_item.icon"
          @change="icon_change_event($event)" /></div>
      <div class="col-md-7">
        <component v-bind:is="this.$microlink.components['select'].component" v-bind:key="menu_item.id"
          v-bind:field="select_meta" v-bind:value="menu_item.path" @input="path_change_event($event)"></component>
      </div>
      <div class="col-md-2">
        <button type="button" class="btn bg-red" title="刪除項目" @click="delete_item">
          <i class="fas fa-trash"></i>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery'
import Item from "./Item.vue"
export default {
  props: ['item'],
  emits: ['input', 'delete_event'],
  components: {
    Item
  },
  data() {
    return {
      component_id: 'date-' + Math.random().toString().substring(2),
      menu_item: this.item,
      select_meta: {
        "variable": "required",
        "label": "頁面",
        "tip": "",
        "component": {
          "type": "select",
          "properties": {
            "required": "Y",
            "default": "",
            "other": "",
            "show_label": "",
            "source_api": "/platform/page/menu/list",
            "col_width": {
              "desktop": 12,
              "pad": 12,
              "mobile": 12
            }
          }
        }
      }
    }
  },
  mounted() {
    this.refresh_sortable_event();
  },
  methods: {
    add_class: function () {
      if (this.menu_item.subitems == undefined) {
        this.menu_item.subitems = [];
      }
      this.menu_item.subitems.push({
        "id": 'item-' + Math.random().toString().substring(2),
        "title": "",
        "icon": "",
        "subitems": []
      });
      this.refresh_sortable_event();
    },
    add_item: function () {
      if (this.menu_item.subitems == undefined) {
        this.menu_item.subitems = [];
      }
      this.menu_item.subitems.push({
        "id": 'item-' + Math.random().toString().substring(2),
        "title": "",
        "icon": "",
        "path": ""
      });
      this.refresh_sortable_event();
    },
    icon_change_event: function ($event) {
      this.menu_item.icon = $event.target.value;
      $event.temp = this.menu_item;
      this.$emit('input', $event);
    },
    title_change_event: function ($event) {
      this.menu_item.title = $event.target.value;
      $event.temp = this.menu_item;
      this.$emit('input', $event);
    },
    path_change_event: function ($event) {
      this.menu_item.path = $event.target.value;
      if ($event.target.selectedOptions != undefined && $event.target.selectedOptions.length > 0) {
        this.menu_item.title = $event.target.selectedOptions[0].label;
        $event.temp = this.menu_item;
        this.$emit('input', $event);
      }
    },
    delete_item: function ($event) {
      this.$emit('delete_event', $event);
    },
    delete_event: function (index, $event) {
      this.menu_item.subitems.splice(index, 1);
      $event.temp = this.menu_item;
      this.$emit('input', $event);
      this.$forceUpdate();
    },
    input_event: function (index, $event) {
      this.menu_item.subitems[index] = ($event.temp == undefined) ? $event.target.value : $event.temp;
      $event.temp = this.menu_item;
      this.$emit('input', $event);
    },
    refresh_sortable_event: function () {
      let $this = this;
      $(this.$refs[this.component_id]).sortable({
        handle: ".item-icon, .list-icon",
        items: "> .item-frame, > .list-frame",
        update: function (event, ui) {
          ui.item = undefined;

          let sort_array = [];
          $('> .item-frame, > .list-frame', $(event.target)).each(function () {
            sort_array.push($(this).attr('id'));
          });

          let sortedArr = $.map(sort_array, function (id) {
            return $.grep($this.menu_item.subitems, function (obj) {
              return obj.id === id;
            })[0];
          });
          $this.menu_item.subitems = sortedArr;
        }
      });
    }
  }
}
</script>

<style scoped>
div.list-frame {
  border: solid 1px gray;
  background-color: #d0ddf5;
  border-radius: 5px;
  padding: 5px;
  margin: 10px 0px;
  margin-top: 30px;
  padding-left: 20px;
}

div.item-frame {
  border: solid 1px gray;
  background-color: #66b5df;
  border-radius: 5px;
  padding: 5px;
  margin: 10px 0px;
}

div.list-icon {
  margin-left: -10px;
  margin-top: 7px;
}

div.item-icon {
  padding: 7px;
}

button {
  margin: 0px 3px;
}
</style>